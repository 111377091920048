<template>
  <div class="price-chart">
    <v-chart class="chart" autoresize ref="priceChart" />
  </div>
</template>

<script>
import "echarts";
import { graphic } from "echarts/core";
import { eventBus } from "../main";

export default {
  name: "FactorAnalysisCumulativeReturnsChart",
  props: {
    data: Object,
    signal: Object
  },
  data() {
    return {};
  },
  methods: {
    setChart: function() {
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "24%",
          bottom: "8%",
          left: "4%",
          right: "4%",
          containLabel: true
          //bottom: "3%"
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: [
          {
            text: "Cumulative Returns Of Prior Signals",
            left: "30",
            top: "5%",
            textStyle: {
              fontFamily: "Chivo-Regular",
              fontWeight: "lighter",
              color: "#ffffff",
              fontSize: 20
            }
          },
          {
            text: "No Data",
            left: "center",
            top: "center",
            show: !this.hasData,
            textStyle: {
              fontFamily: "Chivo-Regular",
              fontWeight: "lighter",
              color: "#ffffff",
              fontSize: 20
            }
          }
        ],
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none"
            },
            restore: {},
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "rgba(186, 116, 227, 0.8)",
              borderColor: "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.hasData ? this.data["index"] : [],
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(234, 234, 234, 0.1)",
              width: 1
            }
          }
        },
        yAxis: [
          {
            type: "value",
            name: "Cumulative Returns",
            show: this.hasData,
            position: "left",
            nameTextStyle: {
              fontSize: 14,
              padding: [0, 0, 0, 30]
            },
            max: function(value) {
              return value.max;
            },
            min: function(value) {
              return value.min;
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        series: [
          {
            data: this.hasData ? this.data["returns"] : [],
            name: "Cumulative Returns",
            type: "line",
            showAllSymbol: false,
            areaStyle: {
              color: new graphic.LinearGradient(1, 0, 1, 1, [
                {
                  offset: 1,
                  color: "rgba(109, 89, 122, 0.2)"
                },
                {
                  offset: 0,
                  color: "rgba(234, 3, 255, 0.2)"
                }
              ])
            },
            lineStyle: { color: "rgba(109, 89, 122, 1)" },
            itemStyle: { color: "rgba(103, 34, 148, 1)" }
          }
        ]
      };

      if (window.innerWidth < 850) {
        this.options["grid"] = {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "23%",
          bottom: "8%",
          left: "9%",
          right: "7%",
          containLabel: false
          //bottom: "3%"
        };
        this.options["toolbox"]["show"] = false;
        this.options["yAxis"][0]["show"] = false;
        this.options["tooltip"]["confine"] = true;
        this.options["title"][0]["text"] = "Cum. Returns Of Prior Signals";
      }
      this.$refs.priceChart.setOption(this.options, true);
    },
    checkData: function() {
      if (this.data == undefined) {
        this.hasData = false;
      } else if (!Object.keys(this.data).length) {
        this.hasData = false;
      } else if (!this.data.index.length) {
        this.hasData = false;
      } else if (Object.keys(this.signal).length == 0) {
        this.hasData = false;
      } else {
        this.hasData = true;
      }
    }
  },
  watch: {
    data: function() {
      this.checkData();
      this.setChart();
    }
  },
  mounted() {
    if (this.data != undefined) {
      this.checkData();
      this.setChart();
    }
    eventBus.$on("breakpoint", () => {
      this.setChart();
    });
  }
};
</script>

<style scoped>
.chart {
  height: 400px;
  width: 100%;
}

.price-chart {
  width: 100%;
}
</style>
